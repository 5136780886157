// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-course-jsx": () => import("../src/pages/course.jsx" /* webpackChunkName: "component---src-pages-course-jsx" */),
  "component---src-pages-home-js": () => import("../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publications-js": () => import("../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-js": () => import("../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */)
}

